<template>
  <div>
    <div class="" v-if="transaction">
      <trac-back-button class="back-btn">Back</trac-back-button>

      <div class="flex justify-center my-4">
        <img
          width="200px"
          src="./../../assets/svg/traction_wallet_transaction_receipt_logo.svg"
          alt=""
        />
      </div>
      <div class="rounded-md text-center p-5">
        <h3 class="text-xl font-bold mb-3">Transactions Receipt</h3>

        <div class="flex justify-between">
          <p class="font-bold">Transaction Details</p>
          <span></span>
        </div>

        <div class="flex justify-between my-3">
          <span class="font-thin">Transaction Date:</span>
          <p class="font-bold">
            {{ moment(transaction.createdOn).format("Do MMM YYYY") }}
          </p>
        </div>
        <div class="flex justify-between my-3">
          <span class="font-thin">Transaction Amount:</span>
          <p class="font-bold">
            {{ transaction.amount | formatPrice(false) }} 
            <!-- ({{
              numbersToWords(transaction.amount)
            }}) -->
          </p>
        </div>
        <div class="flex justify-between my-3">
          <span class="font-thin">Business Name:</span>
          <p class="font-bold">{{ transaction.businessname }}</p>
        </div>
        <!-- <div class="flex justify-between my-3">
        <span class="font-thin">Amount in words:</span>
        <p class="font-bold">Three thousand naira only</p>
      </div> -->
        <div class="flex justify-between my-3">
          <span class="font-thin">Transaction Type:</span>
          <p class="font-bold">{{ transaction.transactiontypename }}</p>
        </div>
        <div class="flex justify-between my-3">
          <span class="font-thin">Reference Number:</span>
          <p class="font-bold">{{ transaction.reference }}</p>
        </div>
        <div class="flex justify-between my-3">
          <span class="font-thin">Transaction Status:</span>
          <p class="font-bold">{{ transaction.transactionstatus }}</p>
        </div>
        <div class="flex justify-between my-3">
          <span class="font-thin">Session ID:</span>
          <p class="font-bold">{{ transaction.sessionid || "N/A" }}</p>
        </div>

        <div class="" v-if="transaction.transactiontypename === 'WalletToBank'">
          <div class="flex justify-between">
            <p class="font-bold">Beneficiary Details</p>
            <span></span>
          </div>

          <div class="flex justify-between my-3">
            <span class="font-thin">Account Number:</span>
            <p class="font-bold">{{ transaction.craccount }}</p>
          </div>

          <div class="flex justify-between my-3">
            <span class="font-thin">Account Name:</span>
            <p class="font-bold">{{ transaction.craccountname }}</p>
          </div>

          <div class="flex justify-between my-3">
            <span class="font-thin">Bank:</span>
            <p class="font-bold">{{ transaction.bankname }}</p>
          </div>
        </div>
        <!-- 
      <div class="border-dotted border-2 my-4 p-3">
        <div class="flex justify-between">
          <p class="font-bold text-xs">Disclaimer</p>
          <span></span>
        </div>

        <p class="text-justify text-xs">
          Your money has been sent and the receiver’s account will be created.
          Please note that this does not confirm that the receives account has
          been credited. You should also know that transfer and payment are
          checked for fraud and require verification. Traction will not take
          responsibility for any fault outside our control that could affect the
          delivery of the sent customer.
        </p>
      </div> -->
      </div>
      <div v-if="reference" class="text-center p-5">
        <div class="flex justify-between">
          <p class="font-bold">Payment Details</p>
          <span></span>
        </div>
        <div v-if="isPrepaid" class="flex justify-between my-3">
          <span class="font-thin">Token:</span>
          <p class="font-bold">{{ reference.PurchasedCode || "N/A" }}</p>
        </div>
        <div v-if="reference && isPrepaid" class="flex justify-between my-3">
          <span class="font-thin">Bonus Token</span>
          <p class="font-bold">{{ reference.bonusToken || "N/A" }}</p>
        </div>
        <div
          v-if="reference && showForCablePrepaidAndPostpaid"
          class="flex justify-between my-3"
        >
          <span class="font-thin">Customer Name</span>
          <p class="font-bold">{{ reference.customerName || "N/A" }}</p>
        </div>

        <div class="flex justify-between my-3">
          <span class="font-thin">{{ formatText }}:</span>
          <p class="font-bold">{{ reference.serviceID }}</p>
        </div>
        <div v-if="reference.variation_code" class="flex justify-between my-3">
          <span class="font-thin">Package Selected:</span>
          <p class="font-bold">{{ reference.variation_code }}</p>
        </div>
        <div v-if="isAirtime" class="flex justify-between my-3">
          <span class="font-thin">Phone Number</span>
          <p class="font-bold">{{ reference.billerCode }}</p>
        </div>
        <div v-if="isInternet" class="flex justify-between my-3">
          <span class="font-thin">Customer ID:</span>
          <p v-if="reference.billerCode" class="font-bold">
            {{ reference.billerCode }}
          </p>
          <p v-if="showForCablePrepaidAndPostpaid" class="font-bold">
            {{ reference.phone || "N/A" }}
          </p>
        </div>
        <div
          v-if="reference.service.includes('ELECTRIC')"
          class="flex justify-between my-3"
        >
          <span class="font-thin">Meter Number:</span>
          <p class="font-bold">{{ reference.billerCode }}</p>
        </div>
        <div v-if="isCable" class="flex justify-between my-3">
          <span class="font-thin">Smartcard Number:</span>
          <p class="font-bold">{{ reference.billerCode }}</p>
        </div>
        <div v-if="reference.cashBackValue" class="flex justify-between my-3">
          <span class="font-thin">Cashback Amount:</span>
          <p class="font-bold">₦ {{ reference.cashBackValue }}</p>
        </div>
      </div>
      <hr class="my-6" />

      <div class="border-dotted border-2 my-4 p-3">
        <div class="flex justify-between">
          <p class="font-bold text-xs">Disclaimer</p>
          <span></span>
        </div>

        <p class="text-justify text-xs">
          Your money has been sent to the receiver's account and will be
          credited accordingly. You should also note that transfers and payments
          are checked for fraud, hence requiring verification. Traction will not
          take responsibility for any fault outside our control that could
          impede crediting the recipient's account.
        </p>
      </div>
      <div class="flex justify-end mt-8 download-btn">
        <trac-button @button-clicked="downloadReceipt">Download</trac-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import moment from "moment";

export default {
  data() {
    return {
      moment,
      transaction: null,
      reference: null,
    };
  },
  computed: {
    isCable() {
      return (
        (this.reference.service || false) === "CABLE" ||
        (this.reference.service || false) === "dstv"
      );
    },
    isAirtime() {
      return (this.reference.service || false) === "AIRTIME";
    },
    isPrepaid() {
      return (this.reference.variation_code || false) === "prepaid";
    },
    isPostpaid() {
      return (this.reference.variation_code || false) === "postpaid";
    },
    isInternet() {
      return (
        (this.reference.service || false) === "INTERNET" ||
        (this.reference.service || false) === "DATA"
      );
    },
    showForCablePrepaidAndPostpaid() {
      return this.isCable || this.isPrepaid || this.isPostpaid;
    },
    formatText() {
      console.log(this.reference.service, "best");
      let text = "";
      if (this.reference && this.reference.service) {
        switch (this.reference.service) {
          case "AIRTIME":
            text = "Service Provider";
            break;

          case "DATA":
            text = "Internet service Provider";
            break;

          case "CABLE":
            text = "Cable TV Provider";
            break;

          case "dstv":
            text = "Cable TV Provider";
            break;

          case "INTERNET":
            text = "Internet Service Provider";
            break;

          default:
            text = "Electricity Disco";
            break;
        }
      }

      return text;
    },
  },
  methods: {
    downloadReceipt() {
      print();
    },
    numbersToWords(n) {
      if (n < 0) return false;
      const single_digit = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      const double_digit = [
        "Ten",
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const below_hundred = [
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
      if (n === 0) return "Zero";
      function translate(n) {
        let word = "";
        if (n < 10) {
          word = single_digit[n] + " ";
        } else if (n < 20) {
          word = double_digit[n - 10] + " ";
        } else if (n < 100) {
          let rem = translate(n % 10);
          word = below_hundred[(n - (n % 10)) / 10 - 2] + " " + rem;
        } else if (n < 1000) {
          word =
            single_digit[Math.trunc(n / 100)] +
            " Hundred " +
            translate(n % 100);
        } else if (n < 1000000) {
          word =
            translate(parseInt(n / 1000)).trim() +
            " Thousand " +
            translate(n % 1000);
        } else if (n < 1000000000) {
          word =
            translate(parseInt(n / 1000000)).trim() +
            " Million " +
            translate(n % 1000000);
        } else {
          word =
            translate(parseInt(n / 1000000000)).trim() +
            " Billion " +
            translate(n % 1000000000);
        }
        return word;
      }
      let result = translate(n);
      return result.trim() + ".";
    },
    async initialize() {
      this.loading = true;
      const response = await this.$store.dispatch(
        "GET_REFERENCE",
        this.transaction.reference
      );
      if (response.status) {
        this.reference = response.data;
        // console.log(this.reference, "lumin"); // Move the console.log here
      }
      this.loading = false;
    },
  },
  async beforeMount() {
    this.transaction = GET_LOCAL_DB_DATA("wallet_transaction");
    if (!this.transaction) {
      this.$router.replace({ name: "MainWallet" });
    }

    await this.initialize();
  },

  destroyed() {
    DELETE_LOCAL_DB_DATA("wallet_transaction");
  },
};
</script>

<style>
@media print {
  .back-btn,
  .download-btn {
    display: none !important;
  }
}
</style>